import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Chip from '@material-ui/core/Chip';
import WifiOff from '@material-ui/icons/WifiOffRounded';
import {Auth} from 'aws-amplify';
import { AmplifySignOut } from '@aws-amplify/ui-react';

const NavItem = styled(NavLink)`
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  margin: auto 30px;
`;

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      useremail: ''
    };
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser().then((user) => this.setState({ useremail: user.attributes.email }));
  }
  render() {
    return (
      <AppBar position="static">
        <Toolbar>
          <NavItem to="/">Home</NavItem>
          <NavItem to="/games">Games</NavItem>
          <NavItem to="/createscenario">New scenario</NavItem>
          <NavItem as="span" css="margin-left: auto;">
            Logged in as {this.state.useremail}
          </NavItem>
          <NavItem as="span"><AmplifySignOut/></NavItem>
            


          {window.navigator.onLine ? null : (
            <Chip
              css={`
                margin-left: auto;
              `}
              icon={<WifiOff />}
              label="Offline mode"
            />
          )}
        </Toolbar>
      </AppBar>
    );
  }
}

export default NavBar;
