import { createAction } from 'redux-starter-kit';

export const loadQuestions = createAction('LOAD_QUESTIONS'); //payload: [] : Array of questions

export const nextQuestion = createAction('NEXT_QUESTION'); //payload:

export const prevQuestion = createAction('PREV_QUESTION'); //payload: null

export const loadInspImages = createAction('LOAD_INSPIRATION_IMAGES'); //payload: Array<string>

export const loadOverlay= createAction('UPDATE_OVERLAY'); //payload: overlay object

export const handleNextQuestion = () => {
  return (dispatch, getState) => {
    const state = getState();
    if (state.activeQuestion < state.questions.length - 1) {
      dispatch(nextQuestion());
      const inspImages = state.questions[state.activeQuestion + 1].inspImages;
      if (inspImages) {
        dispatch(loadInspImages(inspImages));
      }
    }
  };
};

export const handlePrevQuestion = () => {
  return (dispatch, getState) => {
    const state = getState();
    if (state.activeQuestion > 0) {
      dispatch(prevQuestion());
      const inspImages = state.questions[state.activeQuestion - 1].inspImages;
      if (inspImages) {
        dispatch(loadInspImages(inspImages));
      }
    }
  };
};

export const toggleShowInspImages = createAction('TOGGLE_INSPIRATIONAL_IMAGES');
