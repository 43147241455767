import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Dialog } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

class LogbookModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    content: PropTypes.string,
    handleContentChange: PropTypes.func,
    onClose: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      content: this.props.content
    };
  }

  componentDidUpdate(prevProps) {
    const newProps = this.props;
    if (prevProps.content !== newProps.content) {
      this.setState({ content: newProps.content });
    }
  }

  render() {
    return (
      <Dialog open={this.props.isOpen} onClose={this.props.onClose} disableBackdropClick={true} onClick={(e) => e.stopPropagation()} maxWidth='lg' fullWidth={true}>
        <DialogTitle id="max-width-dialog-title">Logbook</DialogTitle>
        <Wrapper
          onClick={(e) => e.stopPropagation()}
        >
          <LogBookTextArea
            onBlur={() => this.props.handleContentChange(this.state.content)}
            cols="30"
            rows="10"
            value={this.state.content}
            onChange={(e) => this.setState({ content: e.target.value })}
          />
        </Wrapper>
        <DialogActions>
        <Button
            variant="contained"
            color="primary"
            onClick={this.props.onClose}
            fullWidth={true}
            style={{marginTop: '10px'}}
          >
            Save and close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: white;
`;

const LogBookTextArea = styled.textarea`
   height: CALC(70vh - 50px);
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
   font-size: 14px;
`;

export default LogbookModal;
