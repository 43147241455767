import styled from 'styled-components/macro';

const ControlButton = styled.button`
  display: inline-block;
  border: 2px solid rgb(255, 255, 255);
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px ${props=>props.active && ', 0px 2px 20px 10px rgba(252, 37, 37, .8)'};
  cursor: pointer;
  text-align: center;
  width: 35px;
  height: 35px;
  padding: 0px;
`;

export default ControlButton;