import { createReducer } from 'redux-starter-kit';
import { scenarioLoaded } from '../actions';
import catImages from '../constants/catNumbersImages';
import stepTypes, { numberToStepType } from '../constants/gameplaySteps';
import penImg from '../images/Pen.png';
import diceImg from '../images/dice_symbol.png';
import bubbleImg from '../images/Bubble_symbol.png';

const reformatQuestionContent =(content) => {
  return content.replace(/\n/g,'<br/>').replace(/\bred\b/, '<span style="color:red;font-weight: bold;"> red </span>').replace(/\byellow\b/, '<span style="color:#fecc00;font-weight: bold;"> yellow </span>').replace(/\bgreen\b/, '<span style="color:green;font-weight: bold;"> green </span>');
}

function extractQuestions(scenario) {
  const { gameCategories, gamePlayQuestions } = scenario;
  return gamePlayQuestions.map((question, index, arr) => {
    let image = null;
    if (
      arr[index - 1] === undefined ||
      question.category !== arr[index - 1].category
    ) {
      image = catImages[question.category - 1];
    } else if (question.gameplayStep) {
      const stepType = numberToStepType(question.gameplayStep.id);
      switch (stepType) {
        case stepTypes.DRAWING:
          image = penImg;
          break;
        case stepTypes.LOGBOOK:
          image = bubbleImg;
          break;
        case stepTypes.DICE:
          image = diceImg;
          break;
        default:
      }
    }
    //TODO: temporary inspiration images only, need to change after changing backend
    const inspImgTmpFolder= 'HB'; //question.insp_folder
    let deck = ['01', '04', '05'];
    const inspImages = deck.map((e) => (`/images/inspiration/${inspImgTmpFolder}/${e}.png`));
    const category = gameCategories[question.category - 1];
    return {
      categoryTitle: category.title,
      title: question.title,
      color: category.colorCode,
      image: image,
      content: reformatQuestionContent(question.content),
      // step: question.gameplayStep ? question.gameplayStep.id : null, //TODO: clean up
      inspImages: inspImages
    };
  });
}

function updateQuestions(state, action) {
  const questions = extractQuestions(action.payload);
  return questions;
}

export default createReducer([], {
  [scenarioLoaded]: updateQuestions
});
