import { createAction } from 'redux-starter-kit';

/**
 ***** Drawing modes *****
 */
export const editModes = {
  NONE: 'NONE',
  POLYLINE: 'POLYLINE',
  DICE: 'DICE',
  ERASE: 'ERASE',
  LOGBOOK: 'LOGBOOK',
  FILTER: 'FILTER'
};

export const changeEditMode = createAction('CHANGE_DRAWING_MODE'); //payload: mode

export const changePenColor = createAction('CHANGE_PEN_COLOR'); //payload: color

export const toggleDiceInfo = createAction('TOGGLE_DICE_INFO'); //payload: shapeId

export const hideDiceInfo = createAction('HIDE_DICE_INFO'); //payload: shapeId

export const changeDiceType = createAction('CHANGE_DICE_TYPE'); //payload: {shapeId, newType}

export const updateDiceContent = createAction('UPDATE_DICE_CONTENT'); //payload: {shapeId, newContent: {problem, potential, solution}}

export const addNewDie = createAction('ADD_NEW_DIE'); //payload: {lat, lng}

export const addPolyline = createAction('ADD_NEW_POLYLINE'); //payload: {path, color}

export const handleAddPolyline = (path) => {
  return (dispatch, getState) => {
    const state = getState();
    const color = state.colorSelected;
    dispatch(addPolyline({ path, color }));
  };
};

export const deleteShape = createAction('DELETE_SHAPE'); //payload: shapeId

export const updateLogbookContent = createAction('UPDATE_LOGBOOK_CONTENT'); //payload: newContent

export const updateVisibilityFilter = createAction('UPDATE_VISIBILITY_FILTER'); //payload: {linesFilter, diceFilter}
