export function normalize(array, keyField) {
  return array.reduce((obj, item) => {
    obj[item[keyField]] = item;
    return obj;
  }, {});
}

export function denormalize(object, keyField) {
  return Object.keys(object).map((key) => {
    const item = object[key];
    return {
      [keyField]: key,
      ...item
    };
  });
}
