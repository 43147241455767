import { connect } from 'react-redux';
import { ActionCreators } from 'redux-undo';
import { saveGame } from '../actions';

import ControlPallette from '../components/ControlPallette';
import {
  changeEditMode,
  addNewDie,
  handleAddPolyline,
  updateLogbookContent
} from '../actions';

const mapStateToProps = (state) => {
  return {
    drawMode: state.drawingMode,
    logbookContent: state.logbook,
    canUndo: state.shapes.past.length > 0,
    canRedo: state.shapes.future.length > 0,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleChangeDrawMode: (newMode) => dispatch(changeEditMode(newMode)),
    handleAddDie: (pos) => dispatch(addNewDie(pos)), //pos: {lat, lng}
    handleAddLine: (path) => dispatch(handleAddPolyline(path)),
    handleLogbookChange: (content) => dispatch(updateLogbookContent(content)),
    onUndo: () => dispatch(ActionCreators.undo()),
    onRedo: () => dispatch(ActionCreators.redo()),
    onSave: () => dispatch(saveGame())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ControlPallette);
