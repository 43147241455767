import React, { Component } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import { editModes } from '../actions';
import penIcon from '../images/pen_symbol.png';
import diceIcon from '../images/dice_symbol.png';
import bubbleIcon from '../images/Bubble_symbol.png';
import MapControl from './MapControl';
import ControlButton from './ControlButton';
import PenColorPicker from '../containers/PenColorPicker';
import LogbookModal from './LogbookModal';
import penColors from '../constants/penColors';
import { Delete } from '@material-ui/icons';
import RedoIcon from '@material-ui/icons/Redo';
import UndoIcon from '@material-ui/icons/Undo';

class ControlPallette extends Component {
  static propTypes = {
    mapInstance: PropTypes.any,
    drawMode: PropTypes.oneOf([
      editModes.NONE,
      editModes.POLYLINE,
      editModes.DICE,
      editModes.ERASE,
      editModes.LOGBOOK
    ]),
    logbookContent: PropTypes.string,
    canUndo: PropTypes.bool,
    canRedo: PropTypes.bool,
    handleChangeDrawMode: PropTypes.func, //args: newMode
    handleAddDie: PropTypes.func, //args: position: {lat, lng}
    handleAddLine: PropTypes.func, //args: {path: {LatLngLiteral}, color}
    handleLogbookChange: PropTypes.func,
    onUndo: PropTypes.func,
    onRedo: PropTypes.func,
    onSave: PropTypes.func
  };
  constructor(props) {
    super(props);
    this.diceListener = null; //google.maps.event.EventListener
    this.polylineListener = null; //google.maps.event.EventListener
    this.polylineTouchListener = null; //google.maps.event.EventListener
  }

  componentDidUpdate(prevProps) {
    if (prevProps.drawMode === this.props.drawMode) {
      return;
    }
    //remove listener
    switch (prevProps.drawMode) {
      case editModes.DICE:
        this.diceListener.remove();
        break;
      case editModes.POLYLINE:
        this.polylineListener.remove();
        this.polylineTouchListener.remove();
        this.enableMap();
        break;
      case editModes.ERASE:
        // this.eraseListener.remove();
        break;
      default:
    }
    const map = this.props.mapInstance;
    //add listener
    switch (this.props.drawMode) {
      case editModes.DICE:
        this.diceListener = map.addListener('click', this.handleDieCreation);
        break;
      case editModes.POLYLINE:
        this.polylineListener = window.google.maps.event.addDomListener(
          map.getDiv(),
          'mousedown',
          (e) => {
            this.disableMap();
            drawFreeHand();
          }
        );
        this.polylineTouchListener = window.google.maps.event.addDomListener(
          map.getDiv(),
          'touchstart',
          (e) => {
            this.disableMap();
            drawFreeHand();
          }
        );
        break;
      case editModes.ERASE:
        // this.eraseListener = map.addListener('mousedown', this.handleEraseShape);
        break;
      default:
    }

    const drawFreeHand = () => {
      const poly = new window.google.maps.Polyline({
        map: map,
        clickable: false
      });
      const move = map.addListener('mousemove', function(e) {
        poly.getPath().push(e.latLng);
      });

      //mouseup-listener
      window.google.maps.event.addDomListenerOnce(map.getDiv(), 'mouseup', (e) => {
        move.remove();
        var path = poly
          .getPath()
          .getArray()
          .map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));
        poly.setMap(null);
        this.props.handleAddLine(path);
      });
      window.google.maps.event.addDomListenerOnce(map.getDiv(), 'touchend', (e) => {
        move.remove();
        var path = poly
          .getPath()
          .getArray()
          .map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));
        poly.setMap(null);
        this.props.handleAddLine(path);
      });
    };
  }

  componentWillUnmount() {
    this.toggleMode(this.props.drawMode);
  }

  disableMap = () => {
    this.props.mapInstance.setOptions({
      draggable: false,
      zoomControl: false,
      scrollwheel: false,
      disableDoubleClickZoom: false
    });
  };

  enableMap = () => {
    this.props.mapInstance.setOptions({
      draggable: true,
      zoomControl: true,
      scrollwheel: true,
      disableDoubleClickZoom: true
    });
  };

  //event: MouseEvent
  handleDieCreation = (event) => {
    const latLng = event.latLng;
    const lat = latLng.lat();
    const lng = latLng.lng();
    this.props.handleAddDie({ lat, lng });
  };

  toggleMode = (newMode) => {
    const { drawMode } = this.props;
    if (drawMode === newMode) {
      this.props.handleChangeDrawMode(editModes.NONE);
    } else {
      this.props.handleChangeDrawMode(newMode);
    }
  };

  handleLogBookSaveClose = () => {
    //console.log('on close');
    this.toggleMode(editModes.LOGBOOK);
    this.props.onSave();
  }

  render() {
    return (
      <>
        <MapControl position={window.google.maps.ControlPosition.TOP_RIGHT}>
          <ControlButton
            active={this.props.drawMode === editModes.LOGBOOK}
            onClick={() => this.toggleMode(editModes.LOGBOOK)}
            title="Open Logbook..."
          >
            <Icon src={bubbleIcon} />
            <LogbookModal
              isOpen={this.props.drawMode === editModes.LOGBOOK}
              content={this.props.logbookContent}
              handleContentChange={this.props.handleLogbookChange}
              onClose={this.handleLogBookSaveClose}
            />
          </ControlButton>
        </MapControl>
        <MapControl position={window.google.maps.ControlPosition.TOP_RIGHT}>
          <ControlButton
            active={this.props.drawMode === editModes.DICE}
            ref={this.diceButtonRef}
            onClick={() => this.toggleMode(editModes.DICE)}
            title="Place a dice..."
          >
            <Icon src={diceIcon} />
          </ControlButton>
        </MapControl>
        <MapControl position={window.google.maps.ControlPosition.TOP_RIGHT}>
          <ControlButton
            active={this.props.drawMode === editModes.POLYLINE}
            ref={this.drawButtonRef}
            onClick={() => this.toggleMode(editModes.POLYLINE)}
            title="Draw on map..."
          >
            <Icon src={penIcon} />
          </ControlButton>
        </MapControl>
        <MapControl position={window.google.maps.ControlPosition.TOP_RIGHT}>
          <PenColorPicker colors={penColors} width="35px" />
        </MapControl>
        <MapControl position={window.google.maps.ControlPosition.TOP_RIGHT}>
          <ControlButton
            active={this.props.drawMode === editModes.ERASE}
            onClick={() => this.toggleMode(editModes.ERASE)}
            title="Delete dice or drawings..."
          >
            <Delete color="secondary"/>
          </ControlButton>
        </MapControl>
        {/* Undo redo button */}
        <MapControl position={window.google.maps.ControlPosition.TOP_RIGHT}>
          <ControlButton
            active={false}
            disabled={!this.props.canRedo}
            onClick={this.props.onRedo}
            title="Redo last action..."
          >
            <RedoIcon color="primary" />
          </ControlButton>
        </MapControl>
        <MapControl position={window.google.maps.ControlPosition.TOP_RIGHT}>
          <ControlButton
            active={false}
            disabled={!this.props.canUndo}
            onClick={this.props.onUndo}
            title="Undo last action..."
          >
            <UndoIcon color="primary" />
          </ControlButton>
        </MapControl>


      </>
    );
  }
}

const Icon = styled.img`
  width: 100%;
`;

export default ControlPallette;
