import { combineReducers } from 'redux-starter-kit';
import undoable from 'redux-undo';
import activeQuestion from './activeQuestion';
import activeOverlay from './activeOverlay';
import drawingMode from './drawingMode';
import colorSelected from './colorSelected';
import dice from './dice';
import polylines from './polylines';
import logbook from './logbook';
import map from './map';
import questions from './questions';
import gameId from './gameId';
import visibilityFilter from './visibilityFilter';
import inspirationImages from './inspirationImages';
import snackbarMessages from './snackbarMessages';
import scenDetails from './scenDetails';

const shapes = combineReducers({
  polylines,
  dice
});

export default combineReducers({
  gameId,
  map,
  activeQuestion,
  questions,
  colorSelected,
  drawingMode,
  shapes: undoable(shapes, { limit: 15 }),
  logbook,
  inspirationImages,
  visibilityFilter,
  snackbarMessages,
  activeOverlay,
  scenDetails
});
