import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { editModes, deleteShape } from '../actions';

function withErasable(Shape) {
  class Erasable extends React.Component {
    static propTypes = {
      shapeId: PropTypes.string,
      drawingMode: PropTypes.string.isRequired,
      dispatchDelete: PropTypes.func
    };

    handleClick = (e) => {
      if (this.props.drawingMode === editModes.ERASE) {
        this.props.dispatchDelete(this.props.shapeId);
      } else if (this.props.onClick) {
        this.props.onClick(e);
      }
    };

    render() {
      //destructure props so this.props.onClick doesn't override this.handleClick
      const { onClick, ...restProps } = this.props;
      return <Shape onClick={this.handleClick} {...restProps} />;
    }
  }

  const mapStateToProps = (state) => {
    return { drawingMode: state.drawingMode };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      dispatchDelete: (shapeId) => dispatch(deleteShape(shapeId))
    };
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Erasable);
}

export default withErasable;
