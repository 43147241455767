import { createAction } from 'redux-starter-kit';
import {API} from 'aws-amplify';

import { denormalize } from '../utils/dataNormalizer';
import { createSnackbarMessage } from './index';

export const saveGameBegin = createAction('SAVE_GAME_BEGIN');

export const saveGameSuccess = createAction('SAVE_GAME_SUCCESS');

export const saveGameFailure = createAction('SAVE_GAME_FAILURE');

export const saveGame = () => {
  //console.log('saving');
  return async (dispatch, getState) => {
    dispatch(saveGameBegin());
    // dispatch(
    //   createSnackbarMessage({ variant: 'loading', message: 'Saving..' })
    // );
    const state = getState();
    const id = state.gameId;
    const { polylines, dice } = state.shapes.present;
    const lineStringFeatures = polylinesToFeatures(polylines);
    const pointFeatures = diceToFeatures(dice);
    const features = lineStringFeatures.concat(pointFeatures);
    const geoJSON = {
      type: 'FeatureCollection',
      features
    };

    try {
      await API.post('ArkiNopoly-API', `/savegameplay/${id}`, {
        body: {
          GamePlayDataLayer: geoJSON,
          logBook: state.logbook
        }
      });

      dispatch(saveGameSuccess());
      dispatch(
        createSnackbarMessage({ variant: 'success', message: 'Saved!' })
      );
    } catch (error) {
      console.log(error);
      alert('Error in saving the game. Please make sure you are the game master.');
      dispatch(saveGameFailure());
      dispatch(
        createSnackbarMessage({ variant: 'error', message: 'Unable to save!' })
      );
    }
  };
};

const polylinesToFeatures = (polylines) => {
  const lineArray = denormalize(polylines, 'shapeId');

  return lineArray.map((item) => {
    const { color, path, shapeId } = item;

    const coordinates = path.map((latLng) => [latLng.lng, latLng.lat]);
    return {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates
      },
      properties: {
        mycolor: color,
        shapeId
      }
    };
  });
};

const diceToFeatures = (dice) => {
  const diceArray = denormalize(dice, 'shapeId');

  return diceArray.map((item) => {
    const {
      shapeId,
      type,
      position,
      problem = null,
      potential = null,
      solution = null
    } = item;

    const coordinates = [position.lng, position.lat];

    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates
      },
      properties: {
        shapeId,
        diceType: type,
        problem,
        potential,
        solution
      }
    };
  });
};
