import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ScenarioCard from '../components/ScenarioCard';
import { startNewGame, createSnackbarMessage } from '../actions';
import NavBar from '../components/NavBar';
import loading from '../images/loading.svg';
import {API} from 'aws-amplify';

class Home extends Component {

  login() {
    this.props.auth.login();
  }
  constructor(props) {
    super(props);
    this.state = {
      scenarios: [],
      isLoadingScenarios:true
    };

  }

  static propTypes = {
    gameId: PropTypes.any,
    createGameFromScenario: PropTypes.func // args: scenId
  };

  componentDidMount() {
    API.get('ArkiNopoly-API','/getscenarios',{})
      .then((response) => {
        console.log(response);
        const scenarios = response;
        scenarios.sort((a,b)=> (b.id-a.id));
        this.setState({
          scenarios:scenarios,
          isLoadingScenarios: false
        });
      })
      .catch((error) => console.log(error));
  }

  handleDeleteScenario = (scenarioId) => async () => {
    if (
      window.confirm(
        `Are you sure you want to delete scenario ID: ${scenarioId}`
      )
    ) {
      const confirmId = window.prompt(
        `Please type the scenario ID you are deleting (${scenarioId}) to confirm`
      );
      if (Number(confirmId) !== Number(scenarioId)) {
        window.alert('Wrong ID');
        return; //stop
      }

      try {  
        await API.del('ArkiNopoly-API',`/deletescenario/${scenarioId}`);
        this.setState((prevState) => {
          const nextScenarios = prevState.scenarios.filter(
            (scen) => scen.id !== scenarioId
          );
          return {
            scenarios: nextScenarios
          };
        }, this.props.onDeleteSuccess);
      } catch (error) {
        console.log(error);
        this.props.onDeleteFailed();
      }
    }
  };

  parseScenarios = (scenarios) => {
    return scenarios.map((scen) => (
      <ScenarioCard
        key={scen.id}
        id={scen.id}
        createdAt={scen.createdAt}
        designerId={scen.designerId}
        scenarioName={scen.name}
        onStartClicked={() => this.props.createGameFromScenario(scen.id)}
        useAsTemplateURL={`/createscenario?template=${scen.id}`}
        onDelete={this.handleDeleteScenario(scen.id)}
      />
    ));
  };

  render() {
    const style = {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'white'
    }
    return (
      <>
        <div>
          <>
            <NavBar auth={this.props.auth} />
            <h3>Select a scenario to play: </h3>
            {this.state.isLoadingScenarios && 
            (<div style={style}>
              <img src={loading} alt="loading" />
            </div>)
            }

            {this.parseScenarios(this.state.scenarios)}
          </>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    gameId: state.gameId
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createGameFromScenario: (scenarioId) => dispatch(startNewGame(scenarioId)),
    onDeleteFailed: () =>
      dispatch(
        createSnackbarMessage({
          variant: 'error',
          message: 'Unable to delete scenario!'
        })
      ),
    onDeleteSuccess: () =>
      dispatch(
        createSnackbarMessage({
          variant: 'success',
          message: 'Successfully deleted scenario'
        })
      )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
