import React, { Component } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

class ColorPicker extends Component {
  static propTypes = {
    dropdownWidth: PropTypes.string,
    width: PropTypes.string,
    colors: PropTypes.array,
    onSelectColor: PropTypes.func,
    selectedColor: PropTypes.string
  };
  static defaultProps = {
    dropdownWidth: '200px',
    width: '50px',
    colors: ['red', 'green', 'blue']
  };

  constructor(props) {
    super(props);
    this.dropDownRef = React.createRef();
    this.state = { showDropdown: false, selectedColor: props.colors[0] };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    if (!this.dropDownRef.current.contains(e.target)) {
      this.hideDropdown();
    }
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({ showDropdown: !prevState.showDropdown }));
  };

  hideDropdown = () => {
    this.setState({ showDropdown: false });
  };

  selectColor = (color) => {
    this.setState({ selectedColor: color });
  };

  render() {
    return (
      <Wrapper
        width={this.props.width}
        ref={this.dropDownRef}
        className={this.props.className}
      >
        <Select
          color={this.props.selectedColor}
          onClick={this.toggleDropdown}
        />

        <DropdownBox
          width={this.props.dropdownWidth}
          visible={this.state.showDropdown}
        >
          {this.props.colors.map((color) => (
            <Swatch
              key={color}
              color={color}
              onClick={() => this.props.onSelectColor(color)}
            />
          ))}
        </DropdownBox>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  position: relative;
  border: 2px solid rgb(255, 255, 255);
  border-radius: 3px;
  width: ${(props) => props.width || '35px'};
  height: ${(props) => props.height || '35px'};
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px;
  padding: 2px;
  background-color: white;
  display: inline-block;
`;

const Select = styled.div`
  ${(props) =>
    props.color
      ? `background-color: ${props.color}`
      : ` background-image: linear-gradient(to right, #e31e40, #df511c, #cf7500, #b89300, #99ad00, #83bd30, #66cb54, #38d978, #25e39c, #25ecbc, #3df4d9, #5ffbf1);`};
  width: 100%;
  height: 100%;
`;

const DropdownBox = styled.div`
  position: absolute;
  top: calc(100% + 12px);
  left: calc(50% - ${(props) => props.width} / 2);
  right: calc(50% - ${(props) => props.width} / 2);
  background: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px;
  border-radius: 4px;
  width: ${(props) => props.width};
  padding: 10px;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  justify-content: space-around;
  &:after {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: white;
    border-width: 10px;
    margin-left: -10px;
  }
`;

const Swatch = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 4px;
  margin: 0 6px 6px 0;
  background-color: ${(props) => props.color || 'red'};
`;

export default ColorPicker;
