import React from 'react';
import styled from 'styled-components/macro';

const CircleCheckbox = React.forwardRef(
  ({ color, onClick, circleSize, spacing, active, className }, ref) => (
    <CheckboxWrapper
      onClick={onClick}
      circleSize={circleSize}
      className={className}
      spacing={spacing}
      ref={ref}
    >
      <Circle color={color} circleSize={circleSize} active={active} />
    </CheckboxWrapper>
  )
);

CircleCheckbox.defaultProps = {
  circleSize: '28px',
  color: 'red',
  spacing: '10px'
};

const CheckboxWrapper = styled.div`
  width: ${(props) => props.circleSize || '28px'};
  height: ${(props) => props.circleSize || '28px'};
  margin-right: 5px;
  flex-shrink: 0;
`;

const Circle = styled.div`
  border-radius: 50%;
  height: 100%;
  width: 100%;
  background: transparent;
  box-shadow: ${(props) =>
    props.active
      ? `inset 0 0 0 5px ${props.color}, 0 0 5px ${props.color}`
      : `inset 0 0 0 ${props.circleSize} ${props.color}`};
  transition: 100ms box-shadow ease;
  cursor: pointer;
`;

export default CircleCheckbox;
