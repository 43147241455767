import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';

import startImg from '../images/Start.png';

const styles = {
  card: {
    display: 'flex',
    textAlign: 'left',
    maxWidth: 700,
    margin: '10px auto'
  },
  image: {
    width: '150px',
    marginRight: '10px'
  },
  subtitle: {
    color: 'grey'
  },
  actionArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto'
  },
  startButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer'
  },
  startIcon: {
    width: 150
  },
  spaced: {
    margin: '10px'
  }
};

const ScenarioCard = ({
  classes,
  id,
  createdAt,
  designerId,
  scenarioName,
  onStartClicked,
  useAsTemplateURL,
  onDelete
}) => (
  <Card className={classes.card}>
    <CardMedia
      image="https://via.placeholder.com/150"
      className={classes.image}
    />
    <CardContent className={classes.details}>
      <h3>{scenarioName}</h3>
      <h4 className={classes.subtitle}>Scenario ID: {id}</h4>
      {/* <p>Designed by: {designerId}</p> */}
      <p>Created at: {createdAt}</p>
      <Link to={useAsTemplateURL}>
        <Button className={classes.spaced} color="primary">
          Use as template
        </Button>
      </Link>
      <Button className={classes.spaced} onClick={onDelete} color="secondary">
        Delete
      </Button>
    </CardContent>
    <div className={classes.actionArea}>
      <button className={classes.startButton} onClick={onStartClicked}>
        <img className={classes.startIcon} src={startImg} alt="Play game" />
      </button>
    </div>
  </Card>
);

ScenarioCard.propTypes = {
  id: PropTypes.any,
  createdAt: PropTypes.string,
  designerId: PropTypes.string,
  onStartClicked: PropTypes.func,
  useAsTemplateURL: PropTypes.string,
  onDelete: PropTypes.func
};

export default withStyles(styles)(ScenarioCard);
