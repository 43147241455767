import { createAction } from 'redux-starter-kit';
import {API} from 'aws-amplify';
import { ActionCreators } from 'redux-undo';

import history from '../history';
import { updateLogbookContent, createSnackbarMessage,loadInspImages,loadOverlay } from './index';
import { preloadImages } from '../utils/preloadImages';

export const fetchGameBegin = createAction('FETCH_GAME_BEGIN');

export const fetchGameSuccess = createAction('FETCH_GAME_SUCCESS');
/* 
payload: {
  scenario: {
    mapCenter: {}, gameCategories: [], gamePlayQuestions: [] }
  },
  gameState: {
    
  }
} */

export const fetchGameFailure = createAction('FETCH_GAME_FAILURE');

export const scenarioLoaded = createAction('SCENARIO_LOADED');
/*payload: {
    mapCenter: {}, questions: [] }
  }
*/
export const gameStateLoaded = createAction('GAME_STATE_LOADED');
/**
payload: {
  type: 'FeatureCollection',
  features: [{type, geometry: {type, coordinates}, properties}]
}
 */
export const extraGameStateLoaded = createAction('EXTRA_GAME_STATE_LOADED');

export const logbookLoaded = createAction('LOGBOOK_LOADED'); //payload: content

export const gameIdUpdated = createAction('GAME_ID_UPDATED');

export const scenDetailsUpdated = createAction('SCEN_DETAILS_UPDATED');

const logBookTemplate=`Identify up to 3 most important challenges regarding movement-related issues: 



Identify up to 3 most important challenges regarding place-related issues:



Identify up to 3 most important opportunities regarding movement-related issues:



Identify up to 3 most important opportunities regarding place-related issues:



Identify up to 3 most important outcomes regarding movement-related issues:



Identify up to 3 most important outcomes regarding place-related issues:



List the 5 most important opportunities for this option:



Select your top 2 opportunities for this option and present them to the larger group, explaining why this would contribute to the success of the project:

`;

const scenarioOverlaySrc= {
  '46': {
    imgSrc:'HB/Opt_05.png',
    bounds:{
      south: -33.857217, 
      west: 151.071650,
      north: -33.848019 , 
      east: 151.081222  
    },
    logBookTemplate 
  },
  '48': {
    imgSrc:'HB/Opt_16.png',
    bounds:{
      south: -33.857217, 
      west: 151.071650,
      north: -33.848019 , 
      east: 151.081222  
    },
    logBookTemplate
  },
  '49': {
    imgSrc:'HB/Opt_15.png',
    bounds:{
      south: -33.857139,
      west:  151.071758,
      north: -33.848317 , 
      east: 151.080725 
    },
    logBookTemplate
  },
};

//TODO: temporary hack to preload images
const loadInspFolderFromQuestions = (questions) => {
  questions.forEach((question) => {
    const folder = 'HB';
    let deck = ['01', '04', '05'];
    const srcs = deck.map((e) => (`/images/inspiration/${folder}/${e}.png`));
    preloadImages(srcs);
  });
};

export const fetchGame = (id) => {
  return async (dispatch, getState) => {
    dispatch(fetchGameBegin());
    dispatch(createSnackbarMessage({ variant: 'loading', message: 'Loading' }));
    try {
      const response = await API.get('ArkiNopoly-API',`/loadgame/${id}`,{})
      //console.log(response);
      const { scenarioId, gameState, logbook } = response;
      const scenResponse = await API.get('ArkiNopoly-API',`/getscenario/${scenarioId}`);
      const scenario = scenResponse.scen;
      // console.log('here scen ', scenario);
      dispatch(scenDetailsUpdated({id: scenarioId, name:scenResponse.name})); 
      dispatch(scenarioLoaded(scenario));
      dispatch(gameStateLoaded(gameState));
      dispatch(updateLogbookContent(logbook));
      dispatch(gameIdUpdated(id));      
      dispatch(fetchGameSuccess());
      dispatch(ActionCreators.clearHistory());
      dispatch(
        createSnackbarMessage({
          variant: 'success',
          message: 'Load game successful!'
        })
      );
      //console.log('must once');
      const st=getState();
      const inspImages = st.questions[st.activeQuestion].inspImages;
      if (inspImages) {
        dispatch(loadInspImages(inspImages));
      }
      
      loadInspFolderFromQuestions(scenario.gamePlayQuestions);
      const scenKey=scenarioId+'';
      console.log('scenkey ', scenKey);
      const ol=scenarioOverlaySrc[scenKey];
      if (ol) {
        dispatch(loadOverlay(ol));
      }

    } catch (error) {
      console.log('ERROR:', error);
      dispatch(fetchGameFailure());
      dispatch(
        createSnackbarMessage({
          variant: 'error',
          message: 'Load game failed!'
        })
      );
    }
  };
};

export const fetchMultipleGames = (ids) => {
  return async (dispatch, getState) => {
    dispatch(fetchGameBegin());
    if (!ids instanceof Array || ids.length === 0) return;
    try {
      const firstGame = await API.get('ArkiNopoly-API',`/loadgame/${ids[0]}`,{});
      //console.log(firstGame);
      const { scenarioId, gameState, logbook } = firstGame;
      const scenario = await API.get('ArkiNopoly-API',`/getscenario/${scenarioId}`,{});
      //console.log(scenario);
      dispatch(scenarioLoaded(scenario.scen));
      dispatch(gameIdUpdated(null));
      dispatch(extraGameStateLoaded(gameState));
      //load overlay too
      // const scenKey=scenarioId+'';
      // const ol=scenarioOverlaySrc[scenKey];
      // if (ol) {
      //   dispatch(loadOverlay(ol));
      // }
      let allLogBooks= `---- Logbook for Game ID ${ids[0]} --- \n ${logbook} \n------ END ------ \n\n`;

      for (let i = 1; i < ids.length; i++) {
        const id = ids[i];
        const gameResponse = await API.get('ArkiNopoly-API',`/loadgame/${id}`,{});
        //console.log(gameResponse);
        const extraGameState = gameResponse.gameState;
        allLogBooks += `---- Logbook Game ID ${ids[i]} --- \n ${gameResponse.logbook} \n------ END ------ \n\n`;
        dispatch(updateLogbookContent(allLogBooks));
        dispatch(extraGameStateLoaded(extraGameState));

      }
    } catch (error) {
      console.log(error);
      dispatch(fetchGameFailure());
    }
  };
};

export const startNewGame = (scenarioId) => {
  return async (dispatch) => {
    try {
      const scenKey=scenarioId+'';
      const ol=scenarioOverlaySrc[scenKey];
      const res = await API.post('ArkiNopoly-API','/creategame', { 
        body: {
          scenario_id: scenarioId,
          logbook_template: (ol && ol.logBookTemplate) ? ol.logBookTemplate : ''
        } });
      dispatch(gameIdUpdated(res.id));
      history.push(`/game/${res.id}`);
    } catch (error) {
      console.log(error);
    }
  };
};
